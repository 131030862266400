<template>
  <div id="pageContentView" class="h-full">
      <div class="bottom_space rounded px-4 h-full bg-white cust_card">
        <div class="flex">
          <span class="py-3 my-2 pr-10 " v-for="(data, index) in moduleList" :key="index" >
              <span @click="openModule(data.entityId, data.entitySlug )"  :class="initialEntityId === data.entityId ? 'text-primary font-semibold border-b-2 pb-2 border-primary transition delay-75' : 'text-text2'" class="heading-4 newFont cursor-pointer" >{{data.entityName}}</span>
          </span>
        </div>
      </div>
      <div :style="`min-height:` +  cardHeight + `px;`" class="">
        <div class="grid xl:grid-cols-3">
          <div class="xl:col-span-2">
            <div class="grid lg:grid-cols-2 gap-4">
              <div class="bg-white rounded cust_card p-4">
                <div class="flex justify-between pb-2">
                  <div>
                    <span class="text-text2 heading-3">Email Template:</span>
                  </div>
                </div>
                <div class="" v-if="emailTemplateList.length > 0">
                  <div v-for="(data, index) in emailTemplateList" :key="index">
                    <div @click="updateETemplateData(data.templateSlug ,data.emailTemplateId)" class="text-left cursor-pointer text-primary mb-0 font-semibold heading-5 p-1">{{ data.templateName }}</div>
                  </div>
                </div>
                <div v-else>
                  <div class="flex p-3 text-sm text-primary bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
                    <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                    <span class="sr-only">Info</span>
                    <div>
                      <p class=" font-medium heading-4">No template found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import ADMINAPI from '@/View/Admin/api/Admin.js'
  export default {
    components: {
    },
    data () {
      return {
        cardHeight: 0,
        initialEntityId: 0,
        message: 'email template',
        moduleList: [],
        moduleEntityId: null,
        moduleSlugName: null,
        emailTemplateList: [],
      }
    },
      created () {
      window.addEventListener('resize', this.resizeWindowHandler())
    },
    mounted () {
      document.title = 'Email Templates'
      this.moduleSlugName = this.$route.params.slug
      this.moduleEntityId = this.$route.params.entityId
      console.log('moduleSlugName', this.moduleSlugName)
      this.getModuleList()
    },
    methods: {
      updateETemplateData (slug, id) {
        this.$router.push({name: 'EditEmailTemplate', params: {slug: this.moduleSlugName, emailTemplateId: id}})
      },
      openModule (id, entitySlug) {
        this.$router.push({name: 'EmailTemplateList', params: {entityId: id, slug: entitySlug}})
        this.moduleEntityId = id
        this.initialEntityId = id
        this.moduleSlugName = entitySlug
        this.GetETemplateList()
      },
      getModuleList () {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading.....'})
        ADMINAPI.getEntityList(
          response => {
            let list = response.Data.tableRow
            if (list !== null) {
              if (this.moduleEntityId === '') {
                this.moduleEntityId = list[0].entityId
                this.moduleSlugName = list[0].entitySlug
              }
              for (let i = 0; i < list.length; i++) {
                if (list[i].entitySlug === 'job' || list[i].entitySlug === 'invoice' || list[i].entitySlug === 'payment' || list[i].entitySlug === 'customer' || list[i].entitySlug === 'employee' || list[i].entitySlug === 'visit' || list[i].entitySlug === 'quotation' || list[i].entitySlug === 'request' || list[i].entitySlug === 'expense') {
                  let data = {
                    entityName: list[i].entityName,
                    entityId: list[i].entityId,
                    entitySlug: list[i].entitySlug
                  }
                  this.moduleList.push(data)
                }
              }
              let data = {
                entityName: 'System Sent',
                entityId: 0,
                entitySlug: 'system_sent'
              }
              this.moduleList.push(data)
              if (this.moduleSlugName !== undefined) {
                for (let i = 0; i < this.moduleList.length; i++) {
                  if (this.moduleSlugName === this.moduleList[i].entitySlug) {
                    console.log('this.moduleSlugName 123', this.moduleList[i].entitySlug, i)
                    this.initialEntityId = i + 2
                  }
                }
              } else {
                this.initialEntityId = this.moduleList[0].entityId
              }
            }
            this.GetETemplateList()
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
        console.log('moduleList', this.moduleList)
      },
      GetETemplateList () {
        if (this.moduleEntityId !== null) {
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading.....'})
          ADMINAPI.getEmailTemplateList(
            this.moduleEntityId,
            response => {
              this.emailTemplateList = response.Data !== null ? response.Data : []
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            },
            error => {
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            }
          )
        }
      },
       resizeWindowHandler() {
        this.cardHeight = window.innerHeight - 158
      }
    }
  }
  </script>
  <style>
  .newFont {
    font-family: 'SF UI Text', sans-serif !important;
  }
</style>
  